<template>
    <v-content>
        <div class="container">
            <v-breadcrumbs :items="snackbar.breadcrumbItems" large></v-breadcrumbs>
            <div slot="table-actions">
                <div class="my-1">
                    <v-btn color="primary" @click.stop="addModal">{{$t('passenger_form')}}</v-btn>
                </div>
            </div>
            <v-expansion-panels hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row align="start">
                                <v-col class="float-right" cols="12" sm="4">
                                    <v-text-field v-model="customerData.fullName"
                                                  dense
                                                  outlined
                                                  :label="$t('full_name')"
                                                  required></v-text-field>
                                </v-col>
                                <v-col class="float-right" cols="12" sm="4">
                                    <v-select v-model="customerData.flightNumber" :items="airList"
                                              item-text="value"
                                              item-value="id"
                                              dense
                                              outlined
                                              :label="$t('flight_number')"></v-select>
                                </v-col>
                                <v-col class="float-right" cols="12" sm="4">
                                    <v-autocomplete v-model="customerData.destination"
                                                    :items="Destinations"
                                                    item-text="value"
                                                    item-value="value"
                                                    dense
                                                    outlined
                                                    :label="$t('table.destinations')"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-btn @click="searchInput" small color="primary" dark>
                                    <i class="material-icons">search</i>{{$t('search')}}
                                </v-btn>
                                <v-btn color="rgb(157,25,25)" class="ml-5" small dark @click="clearInput">
                                    <i class="material-icons">clear</i>{{$t('clear')}}
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            :totalRows="customerData.totalRecords"
                            :pagination-options="{ enabled: true,mode: 'records' ,perPageDropdown: [50, 100,200]}"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'flightDateTime'">
                        <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click.stop="editItem(props.row, 'CustomerForm')">edit</v-icon>
                        <v-icon small @click="deleteItemRoom(props.row)">delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog v-model="snackbar.formModal"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      persistent>
                <v-card>
                    <!-- <component :is="snackbar.dynamicComponent.current"
                        @dialogueClose="dialogueClose"
                        @formResponse="formResponse"
                        v-bind="customerData.updateItem"
             ></component>-->
                    <CustomerForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"></CustomerForm>
                    <CustomerEditForm @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-else-if="snackbar.editFormComponent"
                                      :editItemIndex="customerData.editItemIndex"></CustomerEditForm>
                </v-card>
            </v-dialog>
            <v-dialog v-model="snackbar.dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="customerData.deleteFormData"
                                @formResponse="onResponseDelete"></deleteListData>
            </v-dialog>
            <v-snackbar v-model="snackbar.snackbar"
                        :color="snackbar.color"
                        :timeout="snackbar.timeout"
                        top>
                {{ snackbar.text }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";
    import CustomerForm from "./CustomerForm";
    import CustomerEditForm from "./CustomerEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "AviationCustomerList",
        components: {
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            'deleteListData': deleteListData
        },
        methods: {
            async formData() {


                axios.get("Aviation/DDLAirDestinationByIDAsync")
                    .then(response => {
                        this.Destinations = response.data
                    }).catch(err => {
                        console.log(err)
                    });

                axios.get("Aviation/DDLAirDetailListAsync")
                    .then(response => {
                        this.airList = response.data
                    }).catch(err => {
                        console.log(err)
                    });
            },

            clearInput() {
                this.customerData.fullName = null;
                this.customerData.flightNumber = null;
                this.customerData.destination = null;
                /* this.loadItems();*/
            },
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.status ? 'success' : 'red'
                this.snackbar.text = responseObj.text
                this.snackbar.snackbar = true
                this.loadItems();
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.loadItems()
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber,
                    FromDate: null,
                    ToDate: null
                };
                axios.post('Aviation/GetPassengerInformationListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;

                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItemRoom(item) {
                console.log(item);
                this.snackbar.dialogDelete = true;
                this.customerData.deleteFormData.url = "Aviation/DeletePassengerInformationByIDAsync/" + item.personInformationID;
                this.loadItems();
            },
            onResponseDelete() {
                this.snackbar.dialogDelete = false;
                this.loadItems();
            }
        },
        mounted() {
            this.loadItems()
            this.formData()
        },
        data() {
            return {
                Destinations: [],
                airList: [],
                columns: [
                    {
                        label: 's_no',
                        field: "countIndex"
                    },
                    {
                        label: 'full_name',
                        field: 'fullName'
                    },
                    {
                        label: 'flight_number',
                        field: 'flightNumber'
                    },
                    {
                        label: 'table.destinations',
                        field: 'destination'
                    },
                    {
                        label: 'nationality',
                        field: 'nationality',
                    },
                    {
                        label: 'gender',
                        field: 'gender',
                    },
                    {label: 'actions', field: "actions"}
                ],
                rows: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {}
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "personInformationID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    breadcrumbItems: [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            to: 'AviationDashboard',
                            exact: true

                        },
                        {
                            text: 'Passengers',
                            disabled: true,
                        },
                    ],
                    dynamicComponent: {
                        current: 'CustomerForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
