<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_passenger')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.fullName"
                                    :label="$t('full_name')"
                                    dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.dateOfBirth"
                                            :label="$t('dob')"
                                            clearable
                                                  dense
                                    outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.nationality"
                                    :label="$t('nationality')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.country"
                                    :items="countries"
                                    :label="$t('country')"
                                    clearable
                                            dense
                                    outlined
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <!--<v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.state"
                                    :items="state"
                                    label="State"
                                    clearable
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>-->

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete
                                    v-model="customerData.flightNumber"
                                    :items="airList"
                                            dense
                                    outlined
                                    :label="$t('flight_number')"
                                    clearable
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.flightPNRNo"
                                          dense
                                    outlined
                                    :label="$t('flight_pnr_number')"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.ticketNumber"
                                    :label="$t('ticket_number')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.flightClass"
                                            :items="FlightClasses"
                                            :label="$t('flight_class')"
                                            dense
                                    outlined
                                            item-text="value"
                                            item-value="value"
                            ></v-autocomplete>
                        </v-col>
                        <!--<v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.PassengerType" :items="PassengerTypes"
                                            label="Passenger Type"></v-autocomplete>
                        </v-col>-->
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.passengerServiceID"
                                    :items="Services"
                                    chips
                                    deletable-chips
                                            dense
                                    outlined
                                   :label="$t('services')"
                                    clearable
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="customerData.packageID"
                                    :items="AirPackages"
                                    chips
                                    deletable-chips
                                            dense
                                    outlined
                                    :label="$t('air_package')"
                                    clearable
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>


                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete v-model="customerData.destination"
                                            :items="Destinations"
                                            item-text="value"
                                            dense
                                    outlined
                                            item-value="value"
                                            :label="$t('table.destinations')"></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.flightDateTime"
                                            :label="$t('flight_date')"
                                            clearable
                                                  dense
                                    outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.flightDateTime">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.citizenShipNumber"
                                    :label="$t('citizenshipNumber')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.passportNumber"
                                    :label="$t('passport_number')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.licenseNumber"
                                     :label="$t('licenseNumber') "
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.otherID"
                                    :label="$t('otherID') "
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.document"
                                    :label="$t('table.documents')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-switch
                                    class="col-sm-6 col-md-4"
                                    v-model="customerData.isVerifiedDocument"
                                    :label="$t('is_verified_document')"
                                      dense
                                    outlined
                                    clearable
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="customerData.issuedBy"
                                    :label="$t('issued_by')"
                                          dense
                                    outlined
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="customerData.issuedDate"
                                            :label="$t('issued_date')"
                                            clearable
                                                  dense
                                    outlined
                                            prepend-inner-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.issuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AviationCustomerEditForm",
        data() {
            return {
                customerData: {},
                customerSubmitData: {},
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                PassengerTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: [],
                Issuer: ['E-Sewa'],
                AirPackages: [],

            }
        },
        props: ['editItemIndex']
        ,
        computed: {},
        methods: {
            dialogueClose() {
                this.customerData = {}
                this.customerFormData = []
                this.$emit('dialogueClose')
            },
            formSubmit() {
                this.customerSubmitData = this.customerData
                if (this.customerData.passengerServiceID) {
                    this.customerSubmitData.passengerServiceID = this.customerData.passengerServiceID.join()
                }
                if (this.customerData.packageID) {
                    this.customerSubmitData.packageID = this.customerData.packageID.join()
                }
                this.customerData.age = parseInt(this.customerData.age)
                axios.post('Aviation/UpdatePassengerInformationAsync', this.customerSubmitData)
                    .then(() => {
                        this.message.status = true
                        this.message.text = 'Information is Successfully Updated'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
                    .catch(() => {
                        this.message.status = false
                        this.message.text = 'Error Updating Service, Please Contact Admin'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
            },
            async getState(val) {
                axios.get('Shared/StateListByCountryCode?CountryCode=' + val).then(response => {
                    this.state = response.data
                })
            },
            async formData() {
                axios.get("Shared/CountryList")
                    .then(response => {
                        this.countries = response.data;
                    }).catch(err => {
                    console.log(err)
                });
                axios.get("Aviation/DDLAirDetailListAsync")
                    .then(response => {
                        this.airList = response.data
                    }).catch(err => {
                    console.log(err)
                });

                axios.get("Aviation/DDLAirDestinationByIDAsync")
                    .then(response => {
                        this.Destinations = response.data
                    }).catch(err => {
                    console.log(err)
                })

                axios.get("Aviation/DDLPassengerServiceListAsync").then(response => {
                    this.Services = response.data
                }).catch(err => {
                    console.log(err)
                })

                axios.get("StaticValues/DDLCommonStaticValuesListAsync/101")
                    .then(response => {
                        this.FlightClasses = response.data
                    }).catch(err => {
                    console.log(err)
                })
                axios.get('Aviation/DDLAirPackageListAsync').then(response => {
                    this.AirPackages = response.data
                }).catch(err => {
                    console.log(err)
                });

            },
            getEditData() {
                axios.get('Aviation/GetPassengerInformationByIDAsync/' + this.editItemIndex)
                    .then(response => {
                        this.getState(response.data.country)
                        /*this.fillFormData(response.data)*/
                        this.customerData = response.data
                    })

            },
            fillFormData(data) {
                this.customerData.packageID = data.packageID
                this.customerData.passengerInformationID = data.passengerInformationID
                this.customerData.AirID = data.airID
                this.customerData.PassengerInformationID = data.passengerInformationID
                this.customerData.BranchID = data.branchID
                this.customerData.FirstName = data.firstName
                this.customerData.LastName = data.lastName
                this.customerData.Gender = data.gender
                this.customerData.Age = data.age
                this.customerData.Nationality = data.nationality
                this.customerData.Country = data.country
                this.customerData.State = data.state
                this.customerData.PassengerType = data.passengerType
                this.customerData.FlightPNRNo = data.flightPNRNo
                this.customerData.TicketNumber = data.ticketNumber
                this.customerData.FlightClass = data.flightClass
                this.customerData.IssuedBy = data.issuedBy
                this.customerData.IssuedDate = data.issuedDate
                this.customerData.PassengerServiceID = data.passengerServiceID
                console.log(data);
                this.customerData.Destination = data.destination
                console.log('Destination', this.customerData.Destination)
                this.customerData.FlightNumber = data.flightNumber
                this.customerData.FlightDateTime = data.flightDateTime
                this.customerData.FreeBaggage = data.freeBaggage
                this.customerData.CitizenShipNumber = data.citizenShipNumber
                this.customerData.PassportNumber = data.passportNumber
                this.customerData.LicenseNumber = data.licenseNumber
                this.customerData.OtherID = data.otherID
                this.customerData.Document = data.document
                this.customerData.DateOfBirth = data.dateOfBirth
                this.customerData.IsVerifiedDocument = data.isVerifiedDocument
            }
        },
        mounted() {
            this.formData();
            this.getEditData()
        },
    }
</script>

<style scoped>

</style>