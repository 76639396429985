<template>
  <v-card>
    <v-card-title class="headline">{{ $t("delete") }}?</v-card-title>
    <v-card-text>{{ $t("do_you_want_to_delete") }}?</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="dialogueClose">{{
        $t("no")
      }}</v-btn>
      <v-btn color="green darken-1" text @click="deleteData">{{
        $t("yes")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "deleteModal",
  props: ["deleteFormData"],
  watch: {
    created: {
      handler: function(val) {
        console.log({ val });
      },
      deep: true,
    },
  },
  data() {
    return {
      created: false,
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    deleteData() {
      axios
        .post(this.deleteFormData.url, {})
        .then((response) => {
          this.servicesData.data = response.data;
          this.servicesData.message = response.data.success
            ? "Data Deleted Succcessfully"
            : response.data.errors[0];
          this.servicesData.color = response.data.success ? "success" : "error";
          this.dialogueClose();
        })
        .catch((response) => {
          this.servicesData.data = response.data;
          this.servicesData.message =
            "Error Deleting Data, Please Contact Admin";
          this.servicesData.color = "error";
          this.dialogueClose();
        });
    },
  },
};
</script>

<style scoped></style>
